import { ConnectionMethod, SenderListEventType, SendersAvailable, ValidateSenders} from "receiver-common/sender/senders-available"
import { Sender } from "receiver-common/sender/sender"
import { Receiver } from "receiver-common/sender/receiver";
import { ConfigurableLogger } from "receiver-common/ConfigurableLogger";
import { Browser } from "./browser";

export class WvcConnect {
    private senderElements: {[k: string]: {sender: Sender, element: HTMLDivElement}} = {}
    private sendersAvailable: SendersAvailable
    public receiver: Receiver

    constructor(private browser: Browser, private body: HTMLBodyElement, private sendersContainer: HTMLDivElement) {
        this.receiver = {
            deviceId: this.browser.deviceId,
            label: this.browser.label,
            icon: this.browser.icon
        } as Receiver
    }

    start() {
        let sendersAvailable = new SendersAvailable(this.receiver, new ConfigurableLogger(), ConnectionMethod.WebSocket, 5 * 60 * 1000, ValidateSenders.SecureOnly)
        sendersAvailable.addEventListener(SenderListEventType.scanStart, () => {
            this.body.classList.add("wvcconnect-scanning")
            this.body.classList.remove("wvcconnect-scan-stopped")
        })
        sendersAvailable.addEventListener(SenderListEventType.scanStop, () => {
            this.body.classList.remove("wvcconnect-scanning")
            this.body.classList.add("wvcconnect-scan-stopped")
        })
        sendersAvailable.addEventListener(SenderListEventType.update, (senders: Sender[]) => {
            const senderIds = senders.map(s => s.deviceId)
            const removeIds = Object.keys(this.senderElements).filter(id => senderIds.indexOf(id) < 0)
            for (let removeId of removeIds) {
                this.sendersContainer.removeChild(this.senderElements[removeId].element)
                delete this.senderElements[removeId]
            }
            for (let sender of senders) {
                const existing = this.senderElements[sender.deviceId]
                if (existing) {
                    this.updateSenderHtml(existing.element, sender)
                } else {
                    const newSender = this.createSenderHtml()
                    this.updateSenderHtml(newSender, sender)
                    this.sendersContainer.appendChild(newSender)
                    this.senderElements[sender.deviceId] = {sender: sender, element: newSender}
                }
            }
            if (senders.length > 0) {
                this.body.classList.add("wvcconnect-has-senders")
            } else {
                this.body.classList.remove("wvcconnect-has-senders")
            }
        })
        sendersAvailable.addEventListener(SenderListEventType.connectToSender, (sender: Sender) => {
            this.redirectToSender(sender)
        })
        sendersAvailable.startScan()
        this.sendersAvailable = sendersAvailable
        this.body.addEventListener('mousemove', () => this.sendersAvailable.resetStopWebsocketScanTimer())
    }

    public resumeScan() {
        this.sendersAvailable.startScan()
    }

    private createSenderHtml(): HTMLDivElement {
        const div = document.createElement('div')
        div.classList.add('wvcconnect-sender')
        const label = document.createElement('span')
        label.classList.add('wvcconnect-label')
        const iconContainer = document.createElement('span')
        iconContainer.classList.add('wvcconnect-icon')
        const icon = document.createElement('img')
        icon.addEventListener('error', () => { icon.src = "phone.png" })
        iconContainer.appendChild(icon)
        const id = document.createElement('span')
        id.classList.add('wvcconnect-id')
        div.appendChild(iconContainer)
        div.appendChild(label)
        div.appendChild(id)
        div.addEventListener('mousedown', () => { div.classList.add('pressed') })
        div.addEventListener('mouseup', () => { div.classList.remove('pressed') })
        div.addEventListener('mouseout', () => { div.classList.remove('pressed') })
        div.addEventListener('click', () => {
            div.classList.remove('pressed')
            let id = (div.getElementsByClassName('wvcconnect-id')[0] as HTMLSpanElement).innerText
            if (id && this.senderElements[id]) {
                const sender = this.senderElements[id].sender
                this.redirectToSender(sender)
            }
        })
        return div
    }

    private redirectToSender(sender: Sender) {
        const receiverParam = encodeURIComponent(JSON.stringify(this.receiver))
        if (sender.sslDomain) {
            document.location.href = `https://${sender.sslDomain}:${sender.sslPort ?? 30002}/web-receiver/index.html?receiver=${receiverParam}`
        } else {
            document.location.href = `http://${sender.localIp}:${sender.localPort}/web-receiver/index.html?receiver=${receiverParam}`
        }
    }

    private updateSenderHtml(senderElement: HTMLDivElement, sender: Sender) {
        const label: HTMLSpanElement = senderElement.getElementsByClassName('wvcconnect-label')[0] as HTMLSpanElement
        const iconContainer: HTMLSpanElement = senderElement.getElementsByClassName('wvcconnect-icon')[0] as HTMLSpanElement
        const icon: HTMLImageElement = iconContainer.getElementsByTagName('img')[0] as HTMLImageElement
        const id: HTMLSpanElement = senderElement.getElementsByClassName('wvcconnect-id')[0] as HTMLSpanElement
        label.innerText = sender.label
        icon.src = (document.location.protocol == "http:") ? sender.icon : sender.sslIcon ?? sender.icon
        icon.alt = sender.label
        id.innerText = sender.deviceId
    }
}