import { Log } from "../Log";
import { Sender } from "./sender";

type SenderStorageMap = {[key: string]: SavedSender}

export interface SavedSender extends Sender {
    lastUpdated: number
}

export class SenderStore {
    EXPIRED_SENDERS = 7 * 24 * 60 * 60 * 1000
    private senders: SenderStorageMap = {}
    private storage = window['localStorage']

    constructor(private log: Log) {
        try {
            let str = this.storage?.getItem("sendersOnNetwork")
            if (str) {
                const expired = (new Date()).getTime() - this.EXPIRED_SENDERS
                let senders: SenderStorageMap = JSON.parse(str)
                senders = Object.keys(senders).reduce((m, id, i, allIds) => {
                    const sender = senders[id]
                    if (sender != void 0 && (sender.lastUpdated != void 0 && sender.lastUpdated > expired)) {
                        if (sender.deviceId == `${sender.localIp}:${sender.localPort ?? 30001}`) {
                            const betterSender = allIds.filter(id => senders[id].localIp == sender.localIp && senders[id].deviceId != sender.deviceId)
                            if (betterSender) {
                                log.info(`Manually added sender ${id} now has a better sender record ${betterSender}`)
                            } else {
                                m[id] = sender
                            }
                        } else {
                            m[id] = sender
                        }
                    } else {
                        log.info(`Sender ${id} is expired (no websocket updates)`)
                    }
                    return m
                }, {})
                this.senders = senders
            }
        } catch (error) {
            log?.error(error)
        }
    }

    public get sendersOnNetwork(): SenderStorageMap {
        return this.senders
    }

    public addSenderOnNetwork(sender: Sender): SenderStorageMap {
        const savedSender: SavedSender = {
            ...sender,
            lastUpdated: (new Date()).getTime()
        }
        this.senders[sender.deviceId] = savedSender
        this.saveSendersOnNetwork()
        return this.senders
    }

    private saveSendersOnNetwork() {
        if (this.storage) {
            this.storage.setItem("sendersOnNetwork", JSON.stringify(this.senders))
        } else {
            this.log.info("Not saving to storage because it isn't large.")
        }
    }
}