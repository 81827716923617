import { Cookie } from "./cookie";
import { UAParser } from 'ua-parser-js'
import { IdUtil } from "../../receiver-common/IdUtil";

export class Browser {
    public deviceId: string
    public icon: string
    public label: string

    constructor(public userAgent: string) {
        this.deviceId = Cookie.getCookie('deviceId')
        if (!this.deviceId) {
            this.deviceId = IdUtil.generateId()
        }
        Cookie.setCookie('deviceId', this.deviceId, 90)
        
        this.label = "Web Browser"
        const iconBase = "https://cast2tv.app/receiver-images"
        let icon: string | null = null
        try {
            const parser = new UAParser(userAgent)
            const browser = parser.getResult()
            console?.log?.("Browser Info: " + JSON.stringify(browser))
            const labelParts: string[] = []
            if (browser.browser.name) {
                let name = browser.browser.name.toLocaleLowerCase("en-US")
                // Ignore any browsers named "safari" that aren't macs.  TV user agents are often incorrectly identified as this
                if (name != "safari" || browser.device.vendor?.toLocaleLowerCase("en-US") == "macintosh") {
                    labelParts.push(browser.browser.name)
                }
                for (let type of ["edge", "chrome", "safari", "firefox"]) {
                    if (name.indexOf(type) >= 0) {
                        icon = `${iconBase}/${type}.png`
                        break
                    }
                }
            }
            if (browser.device.vendor) {
                labelParts.push(browser.device.vendor)
                const vendor = browser.device.vendor.split('-').join('').split('_').join('').split(' ').join('').toLowerCase()
                for (let type of ["lg", "tizen", "vestel", "hisense", "toshiba", "noblex", "caixun", "philco", "masterg"]) {
                    if(vendor == type) {
                        icon = `${iconBase}/${type}.png`
                    }
                }
                if (vendor == "samsung") {
                    icon = `${iconBase}/tizen.png`
                }
            } else if (browser.os.name) {
                labelParts.push(browser.os.name)
                let name = browser.os.name.toLocaleLowerCase("en-US")
                if (name == "webos") {
                    icon = `${iconBase}/lg.png`
                } else if (name == "tizen") {
                    icon = `${iconBase}/tizen.png`
                }
            }
            if (browser.device.model) {
                // Mac OS is a better label than "Macintosh"
                if (browser.device.model.toLocaleLowerCase("en-US") == "macintosh" && browser.os.name && labelParts.indexOf(browser.os.name) < 0) {
                    labelParts.push(browser.os.name)
                } else {
                    labelParts.push(browser.device.model)
                }
            } else if (browser.device.type && browser.device.type.length > 0) {
                const firstChar = browser.device.type.charAt(0).toLocaleUpperCase('en-US')
                const remainingChars = browser.device.type.slice(1);
                labelParts.push(firstChar + remainingChars)
            }
            
            if (labelParts.length > 0) {
                this.label = labelParts.join(' ')
            }
        } catch (err) {
            console?.log?.(err)
        }
        this.icon = icon ?? `${iconBase}/generic.png`
    }

    get isEdge(): boolean {
        return /Edg\/[0-9]+/.test(this.userAgent)
    }

    get isInsecurePrivateNetworkRequestsAllowed(): boolean {
        if (this.isEdge) {
          return false
        }
        
        const chromeMatch = this.userAgent.match(/Chrome\/([0-9]+)\./)
        if (chromeMatch && chromeMatch.length > 1) {
          const chrome = parseInt(chromeMatch[1]);
          if (chrome >= 94) {
            return false
          }
        }
        return true
    }
}