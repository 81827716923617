
import { DiscoveryUtil } from "receiver-common/DiscoveryUtil";
import { Cookie } from "./cookie";
import { Browser } from "./browser";
import { Receiver } from "receiver-common/sender/receiver";

enum State {
  Idle = 'idle',
  Busy = 'verifying',
  Error = 'error-state',
  Success = 'success'
}

export class Discovery {
  constructor(private input: HTMLInputElement, private body: HTMLBodyElement, private browser: Browser, private receiver: Receiver) {
    this.show(State.Idle);
    if(document.location.search.indexOf("?c") == 0) {
      this.persistCode(null);
    }
    const code = this.getPersistedCode();
    const isDisconnected = document.location.search.indexOf("?d") == 0
    if (code) {
        input.value = code;
    }
    const lastAccess = Cookie.getCookie('lastAccess');
    if (!isDisconnected && code && code.length > 0 && !lastAccess) {
      this.beginDiscovery(code);
    }
    Cookie.setCookie('lastAccess', (new Date()).toUTCString(), 0.0035); // 5 minute expire

    try {
      if (!this.browser.isInsecurePrivateNetworkRequestsAllowed) {
        this.body.classList.add("chrome94")
        if (this.browser.isEdge) {
          this.body.classList.add("edge")
        }
      }
    } catch(err) {
      console?.log?.(err)
    }
  }

  beginDiscovery(checkCode?: string) {
    const code = checkCode || this.input.value;
    const codeParts = this.input.value.split('.');
    this.show(State.Busy);
    let failures = 0;
    const ipsToCheck = DiscoveryUtil.getIpsToCheck(code, true)

    for (let i=0; i < ipsToCheck.length; i++) {
      const ip = ipsToCheck[i];
      const address = `${ip}/web-receiver/`
      DiscoveryUtil.checkAddress(address, null, () => {
        this.show(State.Success);
        this.persistCode(code);
        setTimeout(() => {
          const receiverParam = encodeURIComponent(JSON.stringify(this.receiver))
          document.location.href = `${address}index.html?receiver=${receiverParam}`;
        }, 500);
      }, (error) => {
        failures += 1;
        if (failures >= ipsToCheck.length) {
          this.show(State.Error);
        }
      });
    }
  }

  getPersistedCode(): string | null {
    return Cookie.getCookie('code');
  }

  persistCode(code: string | null) {
    if (code) {
      Cookie.setCookie('code', code, 90);
    } else {
      window['Cookies'].remove('code');
    }
  }

  show(state: State) {
    this.body.classList.remove(State.Idle);
    this.body.classList.remove(State.Busy);
    this.body.classList.remove(State.Error);
    this.body.classList.remove(State.Success);
    this.body.classList.add(state);
  }

  chromeWarningMore() {
    this.body.classList.toggle("chrome94-more")
  }
}
