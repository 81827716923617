import { Receiver } from "./receiver"
import { Sender, SenderEncoded } from "./sender"
import { SenderStore } from "./sender-store"
import { XhrNetwork } from '../XhrNetwork'
import { CancellableRequest } from '../Network'
import { DiscoveryUtil } from '../DiscoveryUtil'
import { ImageUtil } from "../ImageUtil"
import { Log } from "../Log"
import { IdUtil } from "../IdUtil"
import { fromBase36 } from "../Base36"
import { intToIp } from "../IpUtil"
import { base64 } from "../Base64"
import { ipToInt } from "../IpUtil"
import { toBase36 } from "../Base36"

export enum SenderListEventType {
    update = "update",
    connectToSender = "connect-to-sender",
    scanStop = "scan-stop",
    scanStart = "scan-start"
}

type UpdateEventListener = (senders: Sender[]) => void
type ConnectToSenderEventListener = (sender: Sender) => void
type ScanStartEventListener = () => void
type ScanStopEventListener = () => void

enum RemoteAction {
    SendersOnPrivateNetwork = "sendersOnNetwork",
    ConnectToSender = "connectToSender"
}

interface RemoteMessage {
    action: RemoteAction,
    requestId?: number,
    data: any
}

interface SendersOnPrivateNetwork {
    publicIp: string,
    senders: SenderEncoded[]
}

export interface ConnectToSender {
    sender: SenderEncoded
}

interface ReceiverAnnounce {
    receiver: Receiver
}

interface ActiveSenderOnNetwork {
    sender: SenderEncoded,
}

interface ActiveSender {
    sender: Sender,
    lastSeen: number
    cancellable?: CancellableRequest,
}

export enum ConnectionMethod {
    LongPoll,
    WebSocket
}

export enum ValidateSenders {
    All,
    SecureOnly,
    Never
}

export class SendersAvailable {
    static WEBSOCKET_TIMEOUT = 30 * 60 * 1000
    static INACTIVE_RETRY_INTERVAL = 5000
    static ACTIVE_NOTIFY_INTERVAL = 10000
    static ACTIVE_SENDER_CHECK_INTERVAL = 500
    static ACTIVE_TIMEOUT = 60000
    network: XhrNetwork
    senderStore: SenderStore
    scanSocket: WebSocket | null = null
    inactiveInterval: any | null = null
    announceInterval: any | null = null
    websocketScanStopTimer: any | null = null
    wssUrl: string
    wsUrl: string
    longPollWsUrl: string
    currentWebsocketUrl: string
    listeners = {
        [SenderListEventType.update]: [] as UpdateEventListener[],
        [SenderListEventType.connectToSender]: [] as ConnectToSenderEventListener[],
        [SenderListEventType.scanStart]: [] as ScanStartEventListener[],
        [SenderListEventType.scanStop]: [] as ScanStopEventListener[]
    }
    private activeSendersOnNetwork: {[key: string]: ActiveSender} = {}
    private senderActiveChecks: {[key: string]: CancellableRequest} = {}
    private longPollWs?: CancellableRequest

    // validateSenders is set to SecureOnly for cast2tv because chrome 96 security prevents us from calling discover.gif over http.  Without the ability
    // to do that cast2tv will never show senders in the list. This was discovered loading cast2tv on Carlos's Lg tv.
    public constructor(private receiver: Receiver, private log: Log, private connectionMethod: ConnectionMethod = ConnectionMethod.LongPoll, private websocketTimeoutMs = SendersAvailable.WEBSOCKET_TIMEOUT, private validateSenders: ValidateSenders = ValidateSenders.All) {
        this.network = new XhrNetwork(log)
        this.log.debug("Receiver: " + JSON.stringify(receiver))
        this.senderStore = new SenderStore(log)

        const query = `?deviceId=${encodeURIComponent(this.receiver.deviceId)}&label=${encodeURIComponent(this.receiver.label)}&icon=${encodeURIComponent(this.receiver.icon)}`
        this.wssUrl = `wss://wvcconnect-wss.webvideocaster.com/v1${query}`
        this.wsUrl = `ws://wvcconnect-ws.webvideocaster.com:9000/v1${query}`
        this.longPollWsUrl = `http://wvcconnect-ws.webvideocaster.com:9001/v1/${IdUtil.generateId()}/longPoll`
        this.currentWebsocketUrl = this.wssUrl

        if (!window.WebSocket) {
            this.currentWebsocketUrl = this.longPollWsUrl
        } else if (window['deviceInfo']?.wss == false) {
            this.currentWebsocketUrl = this.wsUrl
        }
    }

    public get allActiveSenders(): ActiveSender[] {
        return Object.keys(this.activeSendersOnNetwork).map(k => this.activeSendersOnNetwork[k]) 
    }
    
    public manuallyAddSender(codeOrIp: string, callback: (Sender?) => void, deviceId?: string, label?: string, icon?: string) {
        let failures = 0;
        let success = false;
        let ipsToCheck: string[] = [];
        codeOrIp = codeOrIp?.trim()
        
        if (codeOrIp && DiscoveryUtil.isIp(codeOrIp)) {
            if (codeOrIp.indexOf(':')) {

            } else {
                ipsToCheck = ["http://" + codeOrIp + ":30001"];
                const codeInt = ipToInt(codeOrIp)
                if(codeInt) {
                    ipsToCheck.push("https://" + toBase36(codeInt) + ".p.wvcast.com:30002")
                }
            }
        } else {
            ipsToCheck = DiscoveryUtil.getIpsToCheck(codeOrIp);
        }
        
        this.log.debug(`ipsToCheck: ${JSON.stringify(ipsToCheck)}`)
        
        let senders = ipsToCheck.map(ip => this.urlToSender(ip, deviceId, label ?? codeOrIp, icon)).filter(sender => sender != null) as Sender[]
        
        this.log.debug(`senders: ${JSON.stringify(senders)}`)
        
        // Check first code with very short timeout, on error retry including ambiguous codes and a longer timeout.
        if (senders.length > 0) {
            const first = senders[0];
            this.log.debug(`checking sender at ${first?.deviceId} named ${first?.label}`);
            this.isSenderActive(first, isActive => {
                if (isActive) {
                    success = true
                    callback(first)
                    this.senderStore.addSenderOnNetwork(first)
                    this.checkInactiveSender(first)
                } else {
                    for (let i=0; i < senders.length; i++) {
                        const sender = senders[i];
                        this.log.debug(`checking sender at ${sender?.deviceId} named ${first?.label}`);
                        this.isSenderActive(sender, isActive => {
                            if (!success && isActive) {
                                success = true
                                callback(sender)
                                this.senderStore.addSenderOnNetwork(sender)
                                this.checkInactiveSender(sender)
                            } else {
                                failures += 1;
                                if (!success && failures >= senders.length) {
                                    callback(void 0)
                                } 
                            }
                        })
                    }
                }
            })
        } else {
            callback(void 0)
        }
    }

    public addEventListener(eventType: SenderListEventType, eventListener: any) {
        this.listeners[eventType].push(eventListener)
    }

    public resetStopWebsocketScanTimer() {
        if (this.websocketScanStopTimer) {
            clearTimeout(this.websocketScanStopTimer) 
        }
        this.websocketScanStopTimer = setTimeout(() => this.stopWebsocketScan(), this.websocketTimeoutMs)
    }
    
    public startScan() {
        this.stopWebsocketScan()
        if (!this.inactiveInterval) {
            this.inactiveInterval = setInterval(() => this.checkInactiveSenders(), SendersAvailable.INACTIVE_RETRY_INTERVAL)
        }
        if (!this.announceInterval) {
            this.announceInterval = setInterval(() => this.checkLongPolls(), SendersAvailable.ACTIVE_SENDER_CHECK_INTERVAL)
        }
        this.resetStopWebsocketScanTimer()

        this.checkInactiveSenders()
        this.notifyActiveSenders()

        if (this.currentWebsocketUrl != this.longPollWsUrl) {
            this.connectWebSocket()
        }
    }
    
    private connectWebSocket() {
        this.log.debug(`Connecting to websocket at ${this.currentWebsocketUrl}`)
        const socket = new WebSocket(this.currentWebsocketUrl)

        let webSocketOpened = false

        socket.addEventListener('open', (event) => {
            this.log.debug("Websocket open")
            webSocketOpened = true
            this.listeners[SenderListEventType.scanStart].forEach(e => e())
        })

        socket.addEventListener('close', (event) => {
            this.log.debug("Websocket closed")
            if (this.websocketScanStopTimer) {
                clearTimeout(this.websocketScanStopTimer)
                this.websocketScanStopTimer = null
            }
            this.scanSocket = null
            this.listeners[SenderListEventType.scanStop].forEach(e => e())

            if (!webSocketOpened && this.currentWebsocketUrl == this.wssUrl) {
                // Netcast tvs immediately send 'close' event on wss urls without sending 'open' or 'error' events
                this.currentWebsocketUrl = this.wsUrl
                this.startScan()
            } else if (!webSocketOpened && this.currentWebsocketUrl == this.wsUrl) {
                // Really old netcast TVs with Safari 5 use an old unofficial websocket protocol, so they have to fallback to long polling
                this.currentWebsocketUrl = this.longPollWsUrl
                this.scanSocket = null
                this.startScan()
            }
        })

        socket.addEventListener('error', (event) => {
            this.log.error("Webosocket error")
            this.log.error(event)
            
            if (!webSocketOpened && this.currentWebsocketUrl == this.wssUrl) {
                // This fallback is for older tvs that may error on wss urls for unexpected reasons like old certificate chains, this
                // falls back to using the non-ssl websocket endpoint.
                this.currentWebsocketUrl = this.wsUrl
                this.startScan()
            } else if (!webSocketOpened && this.currentWebsocketUrl == this.wsUrl) {
                // Really old netcast TVs with Safari 5 use an old unofficial websocket protocol, so they have to fallback to long polling
                this.currentWebsocketUrl = this.longPollWsUrl
                this.scanSocket = null
                this.startScan()
            } else if (this.scanSocket) {
                // If socket hasn't already been stopped, then restart it
                this.startScan()
            }
        })

        socket.addEventListener('message', (event) => {
            this.processRemoteMessage(event.data)
        })

        this.listeners[SenderListEventType.scanStart].forEach(e => e())
        this.scanSocket = socket
    }

    // This stops all activity in the SendersAvailable class and clears the list of active senders
    public stopScan() {
        this.log.debug("Scan stop requested")
        
        if (this.inactiveInterval) {
            clearInterval(this.inactiveInterval)
            this.inactiveInterval = null
        }
        if (this.announceInterval) {
            clearInterval(this.announceInterval)
            this.announceInterval = null
        }

        this.stopWebsocketScan()

        Object.keys(this.senderActiveChecks).forEach(id => this.cancelActiveCheck(id))

        const activeSenders = this.allActiveSenders
        activeSenders.forEach(s => {
            s.cancellable?.cancel()
        })
        this.activeSendersOnNetwork = {}
        this.notifyActiveSenders()
    }

    private stopWebsocketScan() {
        this.log.debug("Websocket scan stop requested")
        if (this.websocketScanStopTimer) {
            clearTimeout(this.websocketScanStopTimer)
            this.websocketScanStopTimer = null
        }
        if (this.scanSocket) {
            const socket = this.scanSocket
            this.scanSocket = null
            socket.close()
        }
        if (this.longPollWs) {
            this.longPollWs?.cancel()
            this.longPollWs = void 0
            this.listeners[SenderListEventType.scanStop].forEach(e => e())
        }
    }

    private processRemoteMessage(data: string) {
        try {
            let message: RemoteMessage = JSON.parse(data)
            this.log.debug("Message from websocket: " + JSON.stringify(message))
            if (message?.action) {
                switch (message.action) {
                    case RemoteAction.SendersOnPrivateNetwork:
                        const sendersOnPrivateNetwork: SendersOnPrivateNetwork = message.data
                        if (sendersOnPrivateNetwork.senders) {
                            sendersOnPrivateNetwork.senders.forEach(s => {
                                const sender = this.convertEncodedSender(s)
                                if (sender) {
                                    this.senderStore.addSenderOnNetwork(sender)
                                    if (!this.activeSendersOnNetwork[sender.deviceId]) {
                                        this.checkInactiveSender(sender)
                                    }
                                }
                            })
                        }
                        break
                    case RemoteAction.ConnectToSender:
                        const connectToSender: ConnectToSender = message.data
                        if (connectToSender.sender) {
                            const sender = this.convertEncodedSender(connectToSender.sender)
                            if (sender) {
                                this.senderStore.addSenderOnNetwork(sender)
                                this.connectToSender(sender)
                            }
                        }
                        break
                }
            }
        } catch(error) {
            this.log.error(error)
        }
    }

    private convertEncodedSender(sender: SenderEncoded): Sender | undefined {
        const decode = window?.atob ?? base64.decode 
        const localIp = sender.localIp ?? (sender.code ? intToIp(fromBase36(sender.code)) : void 0)
        const label = sender.label ?? (sender.label64 ? decode(sender.label64) : void 0)
        const icon = sender.icon ?? (sender.icon64 ? decode(sender.icon64) : void 0)
        if (localIp && label) {
            return {
                publicIp: sender.publicIp,
                deviceId: sender.deviceId,
                stage: sender.stage,
                localIp: localIp,
                localPort: sender.localPort,
                sslDomain: sender.sslDomain,
                sslPort: sender.sslPort,
                label: label,
                icon: icon ?? "",
                sslIcon: sender.sslIcon
            }
        } else {
            return void 0
        }
    }

    private checkLongPolls() {
        if (this.websocketScanStopTimer) {
            this.longPollWebSocketCheck()
        }
        this.announceActiveSenders()
    }

    private longPollWebSocketCheck() {
        if (!this.longPollWs && this.currentWebsocketUrl === this.longPollWsUrl) {
            const connected = () => {
                this.listeners[SenderListEventType.scanStart].forEach(e => e())
            }
            const response = (status, error, data) => {
                this.longPollWs = void 0
                this.log.debug(`long poll websocket status = ${status}`)
                if (!error && status >= 200 && status < 400 && data) {
                    this.processRemoteMessage(data)
                }
            }
            const data = JSON.stringify(this.receiver)
            const headers = {
                "Content-Type": "application/json"
            }
            this.longPollWs = this.network.post(this.longPollWsUrl, headers, data, 35 * 1000, response, connected)
        }
    }

    private announceActiveSenders() {
        for (let sender of this.allActiveSenders) {
            if (!sender.cancellable) {
                // checkDiscovery() is needed because if a phone is idle it loses it's ip, and connections to it will time out.  
                // While the phone's ip is non-functional it may appear that the connection is active, but it's actually 
                // not there.  Checking discovery.gif before the notification request will ensure that it really is alive.
                sender.cancellable = this.checkDiscovery(sender.sender, isActive => {
                    sender.cancellable = void 0
                    if (isActive) {
                        this.notifySender(sender.sender)
                    } else {
                        if (this.validateSenders == ValidateSenders.Never || (this.validateSenders == ValidateSenders.SecureOnly && !sender.sender.sslDomain) || !this.removeSenderFromActiveIfExpired(sender.sender)) {
                            this.sleep(sender, SendersAvailable.INACTIVE_RETRY_INTERVAL)
                        }
                    }
                })
            }
        }
    }

    private checkInactiveSender(sender: Sender) {
        this.isSenderActive(sender, (isActive) => {
            if (isActive) {
                this.notifySender(sender, (new Date()).getTime())
            } else {
                this.removeSenderFromActiveIfExpired(sender)
            }
        })
    }

    // return true if expired
    private removeSenderFromActiveIfExpired(sender: Sender): boolean {
        const existing = this.activeSendersOnNetwork[sender.deviceId]
        if (existing) {
            const msSinceLastSeen = (new Date()).getTime() - existing.lastSeen
            if (msSinceLastSeen > SendersAvailable.ACTIVE_TIMEOUT) {
                this.log.warn(`${existing.sender.label} at ${existing.sender.localIp} no longer exists (missing for ${msSinceLastSeen/1000} seconds)`)
                existing.cancellable?.cancel()
                delete this.activeSendersOnNetwork[sender.deviceId]
                this.notifyActiveSenders()
                return true
            }
        } else {
            //this.log.info(`sender ${sender.label} at ${sender.localIp} does not exist`)
        }
        return false
    }

    private notifySender(sender: Sender, lastSeen?: number) {
        this.cancelNotify(sender)
        switch (this.connectionMethod) {
            case ConnectionMethod.LongPoll:
                this.longPollSender(sender, lastSeen)
                break
            case ConnectionMethod.WebSocket:
                this.notifySenderGif(sender, lastSeen)
                break
        }
    }

    private longPollSender(sender: Sender, lastSeen?: number) {
        const url = `${this.getSenderBase(sender)}/web-receiver-io/connect`
        const data = JSON.stringify({ receiver: this.receiver })
        const headers = { "Content-Type": "application/json" }
        const activeSender: ActiveSender = { sender, lastSeen: lastSeen ?? (new Date()).getTime() }
        const connected = () => {
            this.log.debug(`sender ${sender.label} at ${sender.localIp} is active, cancellable = ${cancellable}`)
            this.activeSendersOnNetwork[sender.deviceId] = activeSender
            this.notifyActiveSenders()
        }
        const response = (status, error, data) => {
            this.log.debug(`connect long poll status = ${status}`)
            if (error != null || status < 200 || status >= 400) {
                // 404 and 503 status may mean the app is old and doesn't support this endpoint
                this.sleep(activeSender, SendersAvailable.INACTIVE_RETRY_INTERVAL)
            } else {
                this.log.debug(data ?? "No connect long poll Data")
                if (data && data.length > 0) {
                    this.processRemoteMessage(data)
                }
                activeSender.cancellable = void 0
            }
        }
        const cancellable = this.network.post(url, headers, data, 35 * 1000, response, connected)
        activeSender.cancellable = cancellable
    }

    public getSenderBase(sender: Sender): string {
        if (sender.sslDomain && (document.location.protocol === "https:" || this.isInsecurePrivateNetworkRequestsDenied)) {
            return `https://${sender.sslDomain}${sender.sslPort != 443 ? ":" : ""}${sender.sslPort == 443 ? "" : "" + (sender.sslPort ?? 30002)}`
        } else {
            return `http://${sender.localIp}:${sender.localPort ?? 30001}`
        }
    }

    private get isInsecurePrivateNetworkRequestsDenied(): boolean {
        const userAgent = navigator.userAgent

        if (/Edg\/[0-9]+/.test(userAgent)) {
          return true
        }
        
        const chromeMatch = userAgent.match(/Chrome\/([0-9]+)\./)
        if (chromeMatch && chromeMatch.length > 1) {
          const chrome = parseInt(chromeMatch[1]);
          if (chrome >= 94) {
            return true
          }
        }
        return false
    }

    private notifySenderGif(sender: Sender, lastSeen?: number) {
        const deviceId = encodeURIComponent(this.receiver.deviceId)
        const icon = encodeURIComponent(this.receiver.icon)
        const label = encodeURIComponent(this.receiver.label)
        const t = new Date().getTime()
        const url = `${this.getSenderBase(sender)}/web-receiver-io/cast2tv.gif?deviceId=${deviceId}&label=${label}&icon=${icon}&t=${t}`

        const cancellable = { cancelled: false, cancel: () => { 
            cancellable.cancelled = true 
        }}
        ImageUtil.loadImage(url, (image) => {
          if (!cancellable.cancelled) {
            this.sleep(activeSender, SendersAvailable.ACTIVE_NOTIFY_INTERVAL)
            this.log.debug(`sender ${sender.label} at ${sender.localIp} is active for cast2tv.  Image Size: ${image?.naturalWidth}x${image?.naturalHeight}`)
            if (image?.naturalWidth == 2 && image?.naturalHeight == 2) {
                this.connectToSender(sender)
            }
          }
        }, (error) => {
          if (!cancellable.cancelled) {
            this.sleep(activeSender, SendersAvailable.INACTIVE_RETRY_INTERVAL)
            this.log.warn(`Failed to notify sender ${sender.label} at ${sender.localIp} about this receiver (it may be older version of app)`)
            this.log.warn(error)
          }
        }, 10000)
        
        const activeSender: ActiveSender = { sender, cancellable, lastSeen: lastSeen ?? (new Date()).getTime() }
        this.activeSendersOnNetwork[sender.deviceId] = activeSender
        this.notifyActiveSenders()
    }

    private checkInactiveSenders() {
        const senders = this.senderStore.sendersOnNetwork
        const ids = Object.keys(senders)
        ids.forEach(id => {
            const sender = senders[id]
            if (sender && !this.activeSendersOnNetwork[sender.deviceId]) {
                //this.log.debug(`checking inactive sender ${sender.label} at ${sender.localIp}`)
                this.checkInactiveSender(sender)
            }
        })
    }

    private cancelNotify(sender: Sender) {
        const active = this.activeSendersOnNetwork[sender.deviceId]
        active?.cancellable?.cancel()
    }

    private isSenderActive(sender: Sender, isActive: (boolean) => void) {
        this.cancelActiveCheck(sender.deviceId)
        if (this.validateSenders == ValidateSenders.Never || (this.validateSenders == ValidateSenders.SecureOnly && !sender.sslDomain)) {
            isActive(true)
        } else {
            const cancellable = this.checkDiscovery(sender, isActiveResult => {
                if (this.senderActiveChecks[sender.deviceId]) {
                    delete this.senderActiveChecks[sender.deviceId]
                }
                isActive(isActiveResult)
            })
            this.senderActiveChecks[sender.deviceId] = cancellable
        }
    }

    private checkDiscovery(sender: Sender, isActive: (boolean) => void): CancellableRequest {
        // Don't send the device id if it was created using a manual code
        const deviceId = (sender.deviceId == sender.localIp || sender.deviceId == sender.sslDomain) ? null : sender.deviceId
        if (this.connectionMethod == ConnectionMethod.LongPoll) {
            return DiscoveryUtil.checkXhrAddress(`${this.getSenderBase(sender)}/web-receiver/`, deviceId, () => isActive(true), () => isActive(false))
        } else {
            return DiscoveryUtil.checkAddress(`${this.getSenderBase(sender)}/web-receiver/`, deviceId, () => isActive(true), () => isActive(false))
        }
    }

    private cancelActiveCheck(deviceId: string) {
        const existingCheck = this.senderActiveChecks[deviceId]
        if (existingCheck) {
            existingCheck.cancel()
            delete this.senderActiveChecks[deviceId]
        }
    }

    private notifyActiveSenders() {
        this.log.debug("Active Senders: " + JSON.stringify(this.activeSendersOnNetwork))
        const activeSenders = this.allActiveSenders.map(s => s.sender)
        this.listeners[SenderListEventType.update].forEach(l => l(activeSenders))
    }

    private connectToSender(sender) {
        this.log.info(`Connecting to ${sender.label} at ${sender.localIp}`)
        this.listeners[SenderListEventType.connectToSender].forEach(l => l(sender))
    }

    private urlToSender(url: string, deviceId?: string, label?: string, icon?: string): Sender | null {
        let parsed = this.parseUrl(url)
        
        if (parsed) {
            return {
                deviceId: deviceId ?? parsed.hostname,
                stage: "",
                publicIp: parsed.hostname,
                localIp: parsed.hostname,
                localPort: parsed.port,
                label: label ?? parsed.hostname,
                icon: icon ?? "",
                sslDomain: parsed.protocol == "https" ? parsed.hostname : void 0,
                sslPort: parsed.protocol == "https" ? parsed.port : void 0, 
                sslIcon: void 0,
            }
        } else {
            return null
        }
    }

    // I don't use URL() class since that isn't available in older versions of chrome
    private parseUrl(url: string): { protocol: string; hostname: string; port: number } | null {
        let protocol: string;
        let hostname: string;
        let port: number;
      
        const protocolIndex = url.indexOf("://");
        if (protocolIndex !== -1) {
          protocol = url.substring(0, protocolIndex);
          url = url.substring(protocolIndex + 3);
        } else {
          return null;
        }
      
        const hostnameIndex = url.indexOf("/");
        if (hostnameIndex !== -1) {
          hostname = url.substring(0, hostnameIndex);
          url = url.substring(hostnameIndex);
        } else {
          hostname = url;
        }
      
        const portIndex = hostname.indexOf(":");
        if (portIndex !== -1) {
          port = parseInt(hostname.substring(portIndex + 1));
          hostname = hostname.substring(0, portIndex);
        } else {
          port = protocol === "https" ? 443 : 80;
        }
      
        return {
          protocol,
          hostname,
          port,
        };
      }
    
    private sleep(activeSender: ActiveSender, duration: number) {
        let retryDelay = setTimeout(() => { activeSender.cancellable = void 0 }, duration)
        activeSender.cancellable = { cancel: () => clearTimeout(retryDelay) }
    }
}