class URLSearchParamsPolyfill {
    private params: Record<string, string[]>;

    constructor(paramsString?: string) {
        this.params = {};

        if (paramsString) {
            if (paramsString.indexOf("?") == 0) {
                paramsString = paramsString.substring(1)
            }
            var pairs = paramsString.split('&');
            for (var i = 0; i < pairs.length; i++) {
                var pair = pairs[i].split('=');
                var name = decodeURIComponent(pair[0]);
                var value = decodeURIComponent(pair[1] || '');
                this.append(name, value);
            }
        }
    }

    append(name: string, value: string): void {
        name = String(name);
        value = String(value);

        if (!this.params.hasOwnProperty(name)) {
            this.params[name] = [];
        }

        this.params[name].push(value);
    }

    toString(): string {
        var query: string[] = [];
        for (var name in this.params) {
            if (this.params.hasOwnProperty(name)) {
                var values = this.params[name];
                for (var i = 0; i < values.length; i++) {
                    query.push(encodeURIComponent(name) + '=' + encodeURIComponent(values[i]));
                }
            }
        }

        return query.join('&');
    }

    get(name: string): string | null {
        var values = this.params[name];
        return values ? values[0] : null;
    }

    getAll(name: string): string[] {
        return this.params[name] || [];
    }

    has(name: string): boolean {
        return this.params.hasOwnProperty(name);
    }

    delete(name: string): void {
        delete this.params[name];
    }

    set(name: string, value: string): void {
        this.params[name] = [String(value)];
    }
}

export const SearchParams = window?.URLSearchParams ?? URLSearchParamsPolyfill

