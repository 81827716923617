const BASE36 = "0123456789abcdefghijklmnopqrstuvwxyz".split('')

export function toBase36(val: number): string {
    let remainder = val
    let encoded = ""
    while (remainder > 0) {
        const i = remainder % BASE36.length
        encoded = String(BASE36[i]) + encoded
        remainder = (remainder - i) / BASE36.length
    }
    return encoded
}

export function fromBase36(value: string): number {
    const chars = value.toLowerCase()
    let decoded = 0
    for (let i = 0; i < chars.length; i++) {
        let idx = BASE36.indexOf(chars[i])!
        if (idx > -1) {
            decoded = (decoded * BASE36.length) + idx
        }
    }
    return decoded
}
