
import { fromBase36, toBase36 } from './Base36';
import { intToIp, ipToInt } from './IpUtil';

const PRIVATE_SUBNETS = [
    { start: 3232235520, end: 3232301055 } /* 196.168.0.0-192.168.255.255 */,
    { start: 167772160, end: 184549375 } /* 10.0.0.0-10.255.255.255 */,
    { start: 2886729728, end: 2887778303 } /* 172.16.0.0-172.31.255.255 */
];
const minIp = 16777216 /* 1.0.0.0 */;

export function codeToIps(code: string, port?: string | null): string[] {
    let p = port ? fromBase36(port) : 30001;
    if (p < 30000) {
        p += 30000;
    }
    const codeInt = fromBase36(code);
    const subnetShortcuts = PRIVATE_SUBNETS.map(subnet => subnet.start)
    let ips: string[] = [];
    if (codeInt < minIp) {
        for (let i = 0; i < subnetShortcuts.length; i++) {
            pushIp(ips, codeInt + subnetShortcuts[i], p)
        }
    } else {
        pushIp(ips, codeInt, p)
    }
    return ips;
}

function pushIp(ips: string[], codeInt: number, p?: number) {
    let ip = intToIp(codeInt);
    if (p && p != 30001 && p != 30002) {
        ips.push(`https://${toBase36(codeInt)}:${p}`);
    }
    ips.push(`https://${toBase36(codeInt)}.p.wvcast.com:30002`);
    ips.push(`http://${ip}:${p ?? 30001}`); 
}


export function ipToCode(ip: string): string | undefined {
    let codeInt = ipToInt(ip) ?? 0
    if (!codeInt) {
        return void 0
    }

    PRIVATE_SUBNETS.forEach(subnet => {
        if (codeInt > subnet.start && codeInt < subnet.end) {
            codeInt = codeInt - subnet.start
        }
    })

    return toBase36(codeInt).toLocaleUpperCase('en')
}
