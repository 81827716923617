import { Log } from './Log';
import { SearchParams } from './SearchParams';

export enum LogType {
  Off = 'off',
  Console = 'console',
  Screen = 'screen',
  Remote = 'remote'
}

export enum LogLevel {
  Debug = 1,
  Info,
  Warn,
  Error
}

export interface LogHistory {
  message: string,
  class: string,
  level: LogLevel
}

export class ConfigurableLogger implements Log {
  private _logType = LogType.Console;
  private _logLevel = LogLevel.Debug;
  private _remoteCallback: (message: string, level: LogLevel) => void;
  private history: LogHistory[] = [];

  constructor(private box?: HTMLDivElement) {
    this.adjustBoxHidden();
  }

  get  logType(): LogType {
    return this._logType;
  }
  set logType(value: LogType) {
    let oldValue = this.logType;
    this._logType = value;
    this.adjustBoxHidden();
    if (oldValue != value) {
      this.appendAllHistory();
    }
  }

  get logLevel(): LogLevel {
    return this._logLevel;
  }
  set logLevel(value: LogLevel) {
    this._logLevel = value;
  }

  get remoteCallback(): (message: string, level: LogLevel) => void {
    return this._remoteCallback;
  }
  set remoteCallback(value: (message: string, level: LogLevel) => void) {
    this._remoteCallback = value;
  }

  error(message: any) {
    this.append(this.toText(message), 'error', LogLevel.Error);
  }

  warn(message: any) {
    this.append(this.toText(message), 'warn', LogLevel.Warn);
  }

  info(message: any) {
    this.append(this.toText(message), 'info', LogLevel.Info);
  }

  debug(message: any) {
    this.append(this.toText(message), 'debug', LogLevel.Debug);
  }

  checkUrl() {
    if (document.location.search) {
      const query = new SearchParams(document.location.search)
      const bug = query.get('bug')
      if (bug != void 0) {
        this._logType = LogType.Screen;
        this.adjustBoxHidden();
      }
    }
  }

  private adjustBoxHidden() {
    if (this.logType == LogType.Screen) {
      if (this.box && this.box.classList) {
        this.box.classList.remove('hidden');
      } else if (this.box) {
        this.box.className = this.box?.className.replace("hidden", "") ?? ""
      }
    } else {
      if (this.box && this.box.classList) {
        this.box.classList.add('hidden');
      } else if (this.box) {
        this.box.className = (this.box?.className) + " hidden"
      }
    }
  }

  private toText(obj: any): string {
    let text: string = obj;
    if (typeof obj != 'string' && typeof obj != 'number') {
      try {
        text = JSON.stringify(obj);
      } catch(error) {
        text = String(obj);
      }
    }
    return text;
  }

  private appendAllHistory() {
    this.history.forEach(h => this.append(h.message, h.class, h.level, false));
  }
  
  private append(message: string, cls: string, level: LogLevel, addToHistory: boolean = true) {
    if (addToHistory) {
      this.history.push({
        message: message,
        class: cls,
        level: level
      });
      while (this.history.length > 20) {
        this.history.shift();
      }
    }
    if (level < this.logLevel) {
      return
    }
    switch(this.logType) {
      case LogType.Off:
        return;
      case LogType.Screen:
        this.appendToScreen(message, cls);
        break;
      case LogType.Console:
        this.appendToConsole(message, cls);
        break;
      case LogType.Remote:
        this.appendToRemote(message, level);
        break;
    }
  }

  private appendToScreen(message: string, cls: string) {
    const div = <HTMLDivElement>document.createElement('div');
    if (div.classList) {
      div.classList.add('log-' + cls);
    }
    div.innerText = message;
    if (this.box) {
      this.box.appendChild(div);
      this.box.scrollTop = this.box.scrollHeight;
    } else {
      console?.log?.(message)
    }
  }

  private appendToConsole(message: string, cls: string) {
    console?.log?.(`${cls}: ${message}`);
  }

  private appendToRemote(message: string, level: LogLevel) {
    this.remoteCallback && this.remoteCallback(message, level);
  }
}