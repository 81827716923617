import { Log } from './Log';
import { Network, NetworkResponse, NetworkConnected, CancellableRequest } from './Network';

enum HTTPMethod {
  Get = "GET",
  Post = "POST",
  Put = "PUT",
  Delete = "Delete",
  Head = "HEAD"
}

export class XhrNetwork implements Network {
  private XHR = XMLHttpRequest;

  constructor(private log?: Log) {
    if (window['korz']) {
      this.XHR = window['korz'].OriginalHttpRequest;
    }
  }

  get(url: string, headers: { [key: string]: any; }, timeout: number = 60000, response?: NetworkResponse, connected?: NetworkConnected): CancellableRequest {
    return this.request(HTTPMethod.Get, url, headers, void 0, timeout, response, connected)
  } 

  post(url: string, headers: { [key: string]: any; }, data: string, timeout: number = 10000, response?: NetworkResponse, connected?: NetworkConnected): CancellableRequest {
    return this.request(HTTPMethod.Post, url, headers, data, timeout, response, connected)
  }

  // It is VERY important that xhr.status or xhr.statusText not be accessed unless xhr.readyState is 4 (DONE). 
  // Accessing either of thoe variables without readyState=4 on netcast devices will hang the connection
  private request(method: HTTPMethod, url: string, headers: { [key: string]: any; }, data?: string, timeout: number = 10000, response?: NetworkResponse, connected?: NetworkConnected): CancellableRequest {
    const xhr = new this.XHR();
    try {
      xhr.onreadystatechange = () => {
        //this.log?.debug("onreadystatechange " + url + " " + xhr.readyState)
        if (xhr.readyState == 1) {
          connected?.();
          connected = void 0
        } else if (xhr.readyState == 4) {
          response?.(xhr.status, null, xhr.responseText);
          response = void 0
          connected = void 0
        }
      }
      xhr.onerror = (error) => {
        this.log?.error(error)
        this.log?.error(JSON.stringify(error))
        response?.(-1, "Error", xhr.readyState == 4 ? xhr.responseText : `Error requesting ${url}`);
        response = void 0
        connected = void 0
      }
      xhr.onload = () => {
        if (xhr.readyState == 4 && xhr.status >= 200 && xhr.status < 400) {
          response?.(xhr.status, null, xhr.responseText);
        } else {
          const message = `Connection incomplete (readyState = ${xhr.readyState}`;
          response?.(-1, message, null);
        }
        response = void 0
        connected = void 0
      }
      xhr.onabort = (error) => {
        this.log?.warn("abort called")
        response?.(-1, `Abort ${error}`, null);
        response = void 0
        connected = void 0
      }
      xhr.open(method, url, true);
      Object.keys(headers).forEach(k => {
        xhr.setRequestHeader(k, headers[k]);
      });
      xhr.timeout = timeout;
      if (["POST", "PUT"].indexOf(method) >= 0) {
        xhr.send(data ?? "");
      } else {
        xhr.send()
      }
      return {
        cancel: () => {
          this.log?.info(`Cancelling connection to ${url}`)
          xhr.abort()
        }
      };
    } catch(error) {
      response?.(-1, error, null);
      response = void 0
      connected = void 0
      return {cancel: () => {}};
    }
  }

}