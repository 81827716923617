import { Browser } from "./browser";
import { Discovery } from "./discovery";
import { WvcConnect } from "./wvconnect";

import 'console-polyfill'; // Needed for xbox 360 since IE doesn't support console

(function() {
    const start = () => {
        const browser = new Browser(navigator.userAgent)
        const body = <HTMLBodyElement>document.body;

        try {
            const senders = <HTMLInputElement>document.getElementById('wvcconnect-senders');
            const wvcConnect = new WvcConnect(browser, body, senders)
            wvcConnect.start()
            window['wvcConnect'] = wvcConnect

            const input = <HTMLInputElement>document.getElementById('code');
            if (input && body) {
                window['discovery'] = new Discovery(input, body, browser, wvcConnect.receiver);
            }
        } catch (err) {
            console.error(err)
        }
    };
    
    window['DomReady'].ready(start);
})();