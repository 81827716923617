export class ImageUtil {
  static loadImage(src: string, success: (image: HTMLImageElement)=>void, failure: (error: any, image: HTMLImageElement)=>void, timeout: number = 10000) {
    let didError = false;
    const image = new Image();
    const requestTimeout = window.setTimeout(() => {
      if (!didError) {
        didError = true;
        failure("Timed out loading image", image);
      }
    }, timeout);
    image.onload = () => {
      clearTimeout(requestTimeout);
      success(image);
    }
    image.onerror = (error) => {
      clearTimeout(requestTimeout);
      if (!didError) {
        didError = true;
        failure(error && typeof error == 'string' ? error : "Failed to load image", image);
      }
    }
    image.src = src
  }
}