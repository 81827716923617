import { ImageUtil } from './ImageUtil';
import { Log } from './Log';
import { CancellableRequest } from './Network';
import { XhrNetwork } from './XhrNetwork';
import { codeToIps } from './CodeUtil';

export class DiscoveryUtil {
  private static ipPattern = /^(https?:\/\/)?(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:[0-9]+)?$/

  static getIpsToCheck(code: string, includeAmbiguous: boolean = true): string[] {
    if (!code) {
      return []
    }
    const codeParts = code.split('.');
    let ipsToCheck: string[] = [];
    let ipCode = codeParts[0].toLowerCase();
    let portCode = codeParts.length > 1 ? codeParts[1].toLowerCase() : null;
    ipsToCheck = ipsToCheck.concat(codeToIps(ipCode, portCode));
    if (ipCode.indexOf('0') > -1 || (portCode && portCode.indexOf('0') > -1)) {
      ipsToCheck = ipsToCheck.concat(codeToIps(ipCode.replace('0', 'o'), portCode && portCode.replace('0', 'o')));
    }
    if (ipCode.indexOf('o') > -1 || (portCode && portCode.indexOf('o') > -1)) {
      ipsToCheck = ipsToCheck.concat(codeToIps(ipCode.replace('o', '0'), portCode && portCode.replace('o', '0')));
    }
    if (ipCode.indexOf('i') > -1 || (portCode && portCode.indexOf('i') > -1)) {
      ipsToCheck = ipsToCheck.concat(codeToIps(ipCode.replace('i', '1'), portCode && portCode.replace('i', '1')));
    }
    return ipsToCheck;
  }

  
  static checkAddress(address: string, deviceId: string | null, success: (address: string)=>void, failure: (error: any, address: string)=>void, timeout: number = 10000): CancellableRequest {
    if (address.charAt(address.length - 1) != '/') {
      address += '/'
    }
    const device = deviceId == null ? "" : `&device=${deviceId}`
    const src = address + `discover.gif?t=${ new Date().getTime() }${device}`;
    const cancellable = { cancelled: false, cancel: () => { cancellable.cancelled = true }}
    ImageUtil.loadImage(src, () => {
      if (!cancellable.cancelled) {
        success(address)
      }
    }, (error) => {
      if (!cancellable.cancelled) {
        failure(error, address)
      }
    }, timeout)
    return cancellable
  }

  static checkXhrAddress(address: string, deviceId: string | null, success: (address: string)=>void, failure: (error: any, address: string)=>void, timeout: number = 5000): CancellableRequest {
    let xhr = new XhrNetwork();
    if (address.charAt(address.length - 1) != '/') {
      address += '/'
    }
    const device = deviceId == null ? "" : `&device=${deviceId}`
    return xhr.get(address + `discover.gif?t=${ new Date().getTime() }${device}`, {}, timeout, (status, error) => {
      if (status >= 200 && status < 400) {
        success(address);
      } else {
        failure(error || `Failed to access ${ address }`, address);
      }
    });
  }

  public static isIp(ip: string): boolean {
    return this.ipPattern.test(ip)
  }
}
